module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-image-virtual-090ea55560/0/cache/gatsby-plugin-image-npm-1.15.0-e4b157097b-32383b8b50.zip/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-3ccbc41932/0/cache/gatsby-remark-images-npm-4.2.0-3385bbcad6-fe1ed1d07e.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-matomo-virtual-2fca062ef7/0/cache/gatsby-plugin-matomo-npm-0.8.3-d613c15907-1f9f8471f6.zip/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://analytics.chrisbeley.com","siteUrl":"https://chrisbeley.com","requireConsent":false,"disableCookies":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-mdx-virtual-dd2a884dd2/0/cache/gatsby-plugin-mdx-npm-1.10.1-40b5deef00-fd1d46736c.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[null],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-images","options":{"maxWidth":683,"showCaptions":true}},{"resolve":"gatsby-remark-code-titles"},{"resolve":"gatsby-remark-prismjs","options":{"inlineCodeMarker":null,"showLineNumbers":false,"noInlineHighlight":false,"prompt":{"user":"chris","host":"localhost","global":false}}}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/build"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-typography-virtual-2b410f71a8/0/cache/gatsby-plugin-typography-npm-3.15.0-855069e00a-b7a16ad600.zip/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    }]
